.editProfile {
  padding: 20px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  background-color: white;

  .editTop {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    .email {
      font-weight: bold;
      font-size: 20px;
      color: var(--twilight);
    }
    .isAdmin {
      color: var(--tangerine);
      border: 0.1rem solid;
      font-size: 0.6rem;
      font-weight: 700;
      padding: 5px;
      border-radius: 10px;
    }
  }

  .editProfileForm{
    display: flex;
    width: 30rem;
    flex-direction: column;
    gap: 20px;
  }
}