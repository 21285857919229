.action {
  display: flex;
  align-items: center;
  gap: 10px;

  .editButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    color: white;
    background-color: dodgerblue;
    cursor: pointer;
    &:hover {
      background-color: #156bbe;
      color: white;
    }
  }

  .deleteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    color: white;
    background-color: crimson;
    cursor: pointer;
    &:hover {
      background-color: #c71035;
      color: white;
    }
  }

  svg {
    font-size: 1.2rem;
  }
}