.admin {
  display: flex;
  background-color: var(--milky-way);
  .adminContainer {
    flex: 6;
    padding: 20px;
    position: relative;
  }
  .page{
    .pageTop{
      display: flex;
      padding-bottom: 20px;
      .pageTitle {
        font-size: 50px;
        font-weight: normal;
        color: var(--twilight);
      }
    }
  }
}