.list{
  padding-left: 20px;
  padding-right: 20px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  border-radius: 10px;
  background-color: white;

  .cellItems {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    //align-items: center;
    gap: 6px;

    .item {
      //margin-bottom: 6px;
      //margin-right: 6px;
      padding: 2px 6px;
      border-radius: 5px;
      border: 1px solid var(--twilight);
      color: var(--twilight);
      font-size: 10px;
      font-weight: 700;
      cursor: pointer;
      &:hover {
        background-color: var(--twilight);
        color: white;
      }
    }
  }

  .empty {
    background-color: var(--attention);
  }
}