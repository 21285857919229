.editBrandForm {
  padding: 20px;
  width: 500px;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 2px 4px 10px 1px rgb(201 201 201 / 47%);
  box-shadow: 2px 4px 10px 1px rgb(201 201 201 / 47%);

  .logo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
    img{
      max-height: 100px;
      width: 100%;
    }
  }

  .formInput {
    width: 100%;
    padding-bottom: 20px;
  }
}