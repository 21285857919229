.brand-card {
  display: flex;
  width: 200px;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  align-content: center;
  border: 0.1rem solid rgba(61, 61, 61, 0.13);
  cursor: pointer;
  transition: transform .2s;

  .add {
    text-align: center;
    color: var(--twilight);
    font-size: 16px;
    font-weight: 500;
    .icon {
      font-size: 50px;
    }
  }

  img {
    width: 100%;
    max-height: 110px;
  }
  &:hover {
    transform: scale(1.1);
  }

  .cardHeader {
    text-align: center;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 45px;
    align-items: center;
    justify-content: center;
  }
}