@import "../../breakpoints";
@import "../../index";
.type-group {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  .type {
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 15rem;
    height: 10rem;
    background-color: white;
    border: 0.1rem solid rgba(61, 61, 61, 0.13);
    padding: $base-padding;
    border-radius: 10px;
    margin: $base-margin;
    cursor: pointer;
    .header{
      color: $primary;
      font-size: 4em;
    }
    .description{
      top: -0.8rem;
      position: relative;
      text-align: center;
    }
  }
}