.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  width: 300px;
  height: 40px;
  border-radius: 50px;
  border: 3px solid #fff;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  input {
    padding-left: 20px;
    font-size: 16px;
    border: none;
    outline: none;
    background-color: transparent;
  }
  .icon {
    margin: 15px;
  }
  &:focus-within {
    border: 3px solid var(--tangerine);
  }
}