.login {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--twilight);
  height: 100vh;

  .logo {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    color: var(--milky-way);
    font-size: 30px;
    font-weight: bold;
    span {
      color: var(--tangerine);
    }
  }

  .loginForm {
    width: 300px;
    .some {
      display: flex;
      background-color: white;
      height: 40px;
      margin-bottom: 20px;
      border-radius: 10px;
    }
    input {
      width: 100%;
      padding-left: 16px;
      background-color: transparent;
      border: none;
      outline: none;
      font-size: 16px;
    }

    .errorText {
      display: flex;
      position: relative;
      justify-content: center;
      color: white;
      font-size: 13px;
      top: -10px;
    }

    button {
      border-radius: 12px;
      padding: 10px;
      width: 100%;
      font-size: 16px;
      color: white;
      background-color: transparent;
      border: 2px solid white;
      cursor: pointer;
      &:hover {
        background-color: white;
        color: var(--midnight);
      }
    }
  }
}