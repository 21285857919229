.attention {
  display: flex;
  background-color: var(--attention);
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 80%;
  width: 100%;

  .text {
    margin-left: 10px;
  }
}