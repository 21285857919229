@import "../../index";
.nav{
  display: flex;
  justify-content: center;
  background-color: $primary;

  .nav-logo {
    padding: var(--base-padding);
    color: white;
    font-size: 1.125rem;
  }
}