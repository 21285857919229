.sidebar {
  flex: 1;
  border-right: 0.5px solid rgb(230,227,227);
  min-height: 100vh;
  background-color: var(--twilight);

  .top {
    height: 5.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      font-size: 20px;
      font-weight: bold;
      color: var(--milky-way);
      span {
        color: var(--tangerine);
      }
    }
    .description {
      font-size: 0.8rem;
      color: var(--milky-way);
    }
  }

  .center {
    padding-left: 10px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 10px;
        font-weight: bold;
        color: var(--milky-way);
        margin-top: 15px;
        margin-bottom: 5px;
        opacity: 65%;
      }

      li {
        display: flex;
        align-items: center;
        min-width: 220px;
        height: 30px;
        padding: 5px;
        cursor: pointer;
        border-radius: 10px 0 0 10px;

        &:hover {
          background-color: var(--milky-way);
          .icon {
          color: var(--midnight)
          }
          span {
            color: var(--midnight);
          }
        }

        .icon {
          padding-left: 10px;
          font-size: 20px;
          color: var(--lavender)
        }

        span {
          font-size: 14px;
          font-weight: 600;
          color: var(--milky-way);
          margin-left: 10px;
        }
      }
    }
  }
}