@import "../../index";
.select-product{
  select {
  width: 100%;
    //display: none;
    padding: $base-padding;
    margin-bottom: $base-margin;
    font-size: $base-font-size;
    height: 3rem;
    outline: none;
    border-radius: 10px;
    border: $base-border;
    cursor: pointer;

    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.78986 7.9856L11.7457 1.61395C12.2566 0.957089 11.7885 2.02816e-07 10.9563 2.02816e-07L1.04471 2.8711e-07C0.212555 2.8711e-07 -0.255537 0.957089 0.255359 1.61395L5.21117 7.9856C5.61152 8.50034 6.3895 8.50034 6.78986 7.9856Z' fill='%231AB3ED'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 0.8rem;
  }
}