@import "../../breakpoints";
.brand-group {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  .brand {
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
    @include sm {
      width: calc(100%/2);
    }

    @include md {
      width: calc(100%/3);
    }
  }
}