.card {
  display: flex;
  width: 200px;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  margin: var(--base-margin);
  align-content: center;
  border: 0.1rem solid rgba(61, 61, 61, 0.13);
  cursor: pointer;
  transition: transform .2s;

  &:hover {
    transform: scale(1.1);
  }

  img {
    width: 100%;
    max-height: 100%;
  }
}