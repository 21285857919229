@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
$base-font-size: 1rem;
$font-size-sm: $base-font-size * 0.75;
$font-size-lg: $base-font-size * 1.5;
$font-size-xl: $base-font-size * 2;
$font-size-xxl: $base-font-size * 3;
$primary: #1AB3ED;
$base-margin: 0.75rem;
$base-padding: 0.75rem;
$base-border: 0.1rem solid rgba(61, 61, 61, 0.13);
$base-box-shadow: 2px 4px 10px 1px rgb(201 201 201 / 47%);

:root {
  --primary: #1AB3ED;
  --background: #F5F9FA;
  --text: #02465F;
  --base-padding: 0.75rem;
  --base-margin: 0.75rem;
  --base-border-radius: 20px;
  --base-box-shadow: 2px 4px 10px 1px rgb(201 201 201 / 47%);

  --milky-way: #f8f8fb;
  --tangerine: #ff6a3d;
  --midnight: #1a2238;
  --twilight: #29375b;
  --daffodil: #f9db6d;
  --lavender: #9daaf2;
  --attention: rgba(249, 219, 109, 0.16)
}

* {
  color: inherit;
  margin: 0;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  background-color: var(--background);
  color: var(--text);
}

ul {
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

hr {
  border: 0;
  border-top: 1px dotted #efefef;
}

img {
  max-width: 100%;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: var(--base-padding);
}