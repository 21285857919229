.goBackButton {
  position: fixed;
  display: flex;
  margin-left: -0.5rem;
  align-items: center;
  color: var(--twilight);
  background-color: white;
  font-weight: 600;
  cursor: pointer;
  bottom: 1rem;
  font-size: 15px;
  padding: 0.5rem 1rem 0.5rem 0.80rem;
  border-radius: 0.8rem;
  -webkit-box-shadow: 2px 4px 10px 1px rgb(201 201 201 / 47%);
  box-shadow: 2px 4px 10px 1px rgb(201 201 201 / 47%);
  z-index: 3;

  &:hover {
    background-color: var(--twilight);
    color: white;
  }
}