@import "../../index";

.public {
  .title {
    display: flex;
    justify-content: center;
    font-size: $font-size-lg;
    font-weight: 500;
    margin-bottom: $base-margin;
  }
}